import { Alert, Box, Link } from "@mui/material";
import { projectMockData } from "../../models/Projects";
import DemographicBreakdown from "./DemographicBreakdown";
import Description from "./Description";
import JumpTo from "./JumpTo";
import PatientEncounters from "./PatientEncounters";
import ProjectMetricCards from "./projectmetriccards/ProjectMetricCards";
import PreviewProjectCreated from "./PreviewProjectCreated";
import React, { useState } from "react";

export default function PreviewModeOverview({ project, deviceSearched }) {
  const [displayModal, setDisplayModal] = useState(false);

  function closeModal() {
    setDisplayModal(false);
  }

  function openModal() {
    setDisplayModal(true);
  }

  return (
    <Box className="description-body">
      <div className={"description-left-panel"}>
        <Alert
          severity="info"
          className="description-left-panel-alert"
          title="true"
        >
          <div
            className="preview-info-alert-content"
            data-testid="PreviewModeOverview-text"
          >
            This is a Preview Project with access only to aggregate data. For
            access to all features and more detailed patient data, please{" "}
            <Link
              onClick={openModal}
              className="preview-info-alert-content-link"
              component={"button"}
            >
              contact us
            </Link>{" "}
            to learn more about upgrading this project.
          </div>
        </Alert>
        <Description project={project} />
        <JumpTo version={"Preview Mode"} />
      </div>
      <div className="description-scroll">
        <div className="stats-container">
          {deviceSearched.totalPatients < 10 ? (
            <PreviewProjectCreated displayTitle />
          ) : (
            <ProjectMetricCards data={deviceSearched} />
          )}
        </div>
        {deviceSearched.totalPatients >= 10 && (
          <PatientEncounters data={deviceSearched} />
        )}
        <DemographicBreakdown
          data={projectMockData}
          version={"Preview Mode"}
          closeModal={closeModal}
          openModal={openModal}
          displayModal={displayModal}
        />
      </div>
    </Box>
  );
}
