import { ChevronRight } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Tooltip
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../shared/Footer";
import { RequireAuth, useFusionAuth } from "@fusionauth/react-sdk";
import { useFetch } from "../../../services/hooks/useFetch";
import { apiPrefix } from "../../../utils/main";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InfoIcon from "@mui/icons-material/Info";
import theme from "../../../styles/theme";

dayjs.extend(timezone);
dayjs.extend(utc);

export default function ProtocolPage({ projectId }) {
  const [editMode, setEditMode] = useState(false);

  const { data: project, fetch: getProject } = useFetch();

  const { update: updateProjectProtocol } = useFetch();

  const [startDate, setStartDate] = useState(dayjs(Date.now()));
  const [endDate, setEndDate] = useState(dayjs(Date.now()));
  const [daysPreIndex, setDaysPreIndex] = useState(0);
  const [daysPostIndex, setDaysPostIndex] = useState(0);
  const [actualPatientCohort, setActualPatientCohort] = useState(0);
  const [expectedPatientCohort, setExpectedPatientCohort] = useState(0);
  const [manualCohort, setManualCohort] = useState("");
  const [details, setDetails] = useState("");
  const [updateDetails, setUpdateDetails] = useState("");

  const [notesPreIndex, setNotesPreIndex] = useState("");
  const [notesPostIndex, setNotesPostIndex] = useState("");

  const [displayErrors, setDisplayErrors] = useState(false);

  const [resetValues, setResetValues] = useState(false);

  const { userInfo } = useFusionAuth();
  const isAdmin = userInfo?.roles?.includes("ADMIN");

  const location = useLocation();

  const setProjectValues = function(project) {
    if (project) {
      if (project.startDate) {
        setStartDate(dayjs.tz(project.startDate, "UTC"));
      }

      if (project.endDate) {
        setEndDate(dayjs.tz(project.endDate, "UTC"));
      }

      if (project.defaultPreIndexDays) {
        setDaysPreIndex(project.defaultPreIndexDays);
      }

      if (project.defaultPostIndexDays) {
        setDaysPostIndex(project.defaultPostIndexDays);
      }

      if (typeof project.patientCount !== "undefined") {
        if (project.patientCount === null) {
          setActualPatientCohort(0);
        } else {
          setActualPatientCohort(project.patientCount);
        }
      }

      if (project.expectedPatientCount) {
        setExpectedPatientCohort(project.expectedPatientCount);
      }

      if (project.additionalCriteria) {
        setDetails(project.additionalCriteria);
      }

      if (typeof project.patientIdList !== "undefined") {
        if (project.patientIdList === null) {
          setManualCohort("");
        } else {
          let localCohort = "";

          for (let i = 0; i < project.patientIdList.length; i++) {
            if (i === project.patientIdList.length - 1) {
              localCohort = localCohort + project.patientIdList[i];
            } else {
              localCohort = localCohort + project.patientIdList[i] + ",\n";
            }
          }

          setManualCohort(localCohort);
        }
      }

      let projectNotesPreIndex = project?.projectSettings?.find(s => s.name === "NOTES_PRE_INDEX")?.value;
      if( typeof projectNotesPreIndex !== "undefined" ) {
        setNotesPreIndex(projectNotesPreIndex)
      }

      let projectNotesPostIndex = project?.projectSettings?.find(s => s.name === "NOTES_POST_INDEX")?.value;
      if( typeof projectNotesPostIndex !== "undefined" ) {
        setNotesPostIndex(projectNotesPostIndex);
      }
    }
  };

  useEffect(() => {
    getProject(`${apiPrefix}/projects/${projectId}`);

    if (resetValues) setResetValues(false);
  }, [projectId, resetValues, getProject]);

  useEffect(() => {
    setProjectValues(project);
  }, [project]);

  const updateProjectSetting = function(project, settingName, settingValue) {
    let projectSetting = project.projectSettings.find(s => s.name === settingName);
    let hasValue = (typeof(settingValue)!=="undefined" && settingValue!==null && settingValue!=="");

    if( projectSetting && !hasValue ) {
      // Delete setting
      project.projectSettings = project.projectSettings.filter(s => s.name !== settingName );
    } else if ( projectSetting && hasValue ) {
      // Update setting
      projectSetting["value"] = settingValue;
    } else if ( !projectSetting && hasValue ){
      // Create setting
      project.projectSettings.push({
        "name": settingName,
        "value": settingValue
      })
    }
  }

  const submitProjectSettings = function() {
    if (
      startDate === null ||
      endDate === null ||
      daysPreIndex === "" ||
      daysPostIndex === "" ||
      updateDetails === ""
    ) {
      setDisplayErrors(true);
      return;
    }

    let manualCohortPresent = manualCohort !== "";

    project.startDate = startDate;
    project.endDate = endDate;
    project.defaultPreIndexDays = daysPreIndex;
    project.defaultPostIndexDays = daysPostIndex;
    project.expectedPatientCount = expectedPatientCohort;
    project.additionalCriteria = details;

    if (manualCohortPresent) {
      let tempCohortArray = manualCohort
        .split(",")
        .map((v) => v.trim())
        .filter((v) => v.length > 0);

      project.isManualCohort = true;
      project.patientIdList = tempCohortArray;
    } else {
      project.isManualCohort = false;
      project.patientIdList = null;
    }

    updateProjectSetting(project, "NOTES_PRE_INDEX", notesPreIndex);
    updateProjectSetting(project, "NOTES_POST_INDEX", notesPostIndex);

    updateProjectProtocol(
      `${apiPrefix}/projects/${projectId}`,
      {
        reason: updateDetails,
        project: project
      },
      "PUT"
    );

    setUpdateDetails("");
    setEditMode(false);
    setDisplayErrors(false);
  };

  return (
    <div>
      <Grid
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <br />
        <div className="company-settings-item">
          <Grid item>
            <Breadcrumbs>
              {location.state?.previousPath ? (
                <Link
                  underline="hover"
                  color="inherit"
                  to={location.state.previousPath}
                >
                  {project.name}
                </Link>
              ) : (
                <Link
                  underline="hover"
                  color="inherit"
                  to={`/details?projectId=${projectId}`}
                >
                  {project.name}
                </Link>
              )}
              <Link
                underline="hover"
                color="inherit"
                to={`/details?projectId=${projectId}`}
              >
                Project Settings
              </Link>
              <Typography>Protocol</Typography>
            </Breadcrumbs>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="h4">Protocol</Typography>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="body2">
              Inclusion/exclusion criteria parameters used to curate the patient
              cohort and the longitudinal included in the project.
            </Typography>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="body2">* Required fields</Typography>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="body1">Procedure Date Range</Typography>
          </Grid>
          <br />
          <Grid item container justifyContent={"space-between"}>
            <Grid item>
              {editMode && isAdmin ? (
                <FormControl error={displayErrors && startDate === null}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={["year"]}
                      readOnly={!editMode || !isAdmin}
                      required
                      label={"Start Date *"}
                      sx={{ width: "25ch" }}
                      value={startDate}
                      onChange={(event) => {
                        setStartDate(dayjs.tz(event, "UTC"));
                      }}
                    />
                    <FormHelperText>
                      {displayErrors && startDate === null
                        ? "This field is required"
                        : ""}
                    </FormHelperText>
                  </LocalizationProvider>
                </FormControl>
              ) : (
                <>
                  <Typography variant="caption">Start Date *</Typography>
                  <Typography variant="body1" sx={{ width: "25ch" }}>
                    {startDate.year()}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item>
              <ChevronRight />
            </Grid>
            <Grid item>
              {editMode && isAdmin ? (
                <FormControl error={displayErrors && endDate === null}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={["year"]}
                      readOnly={!editMode || !isAdmin}
                      required
                      label={"End Date *"}
                      sx={{ width: "25ch" }}
                      value={endDate}
                      onChange={(event) => {
                        setEndDate(dayjs.tz(event, "UTC"));
                      }}
                    />
                    <FormHelperText>
                      {displayErrors && endDate === null
                        ? "This field is required"
                        : ""}
                    </FormHelperText>
                  </LocalizationProvider>
                </FormControl>
              ) : (
                <>
                  <Typography variant="caption">End Date *</Typography>
                  <Typography variant="body1" sx={{ width: "25ch" }}>
                    {endDate.year()}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="body1">Default Date Range</Typography>
          </Grid>
          <br />
          <Grid item container justifyContent={"space-between"}>
            <Grid item>
              {editMode && isAdmin ? (
                <TextField
                  required
                  label={"Days Pre-Index Procedure"}
                  type="number"
                  sx={{ width: "25ch" }}
                  inputProps={{ readOnly: !editMode || !isAdmin }}
                  InputLabelProps={{ shrink: true }}
                  value={daysPreIndex}
                  error={displayErrors && daysPreIndex === ""}
                  helperText={
                    displayErrors && daysPreIndex === ""
                      ? "This field is required"
                      : ""
                  }
                  onChange={(event) => {
                    setDaysPreIndex(event.target.value);
                  }}
                ></TextField>
              ) : (
                <>
                  <Typography variant="caption">
                    Days Pre-Index Procedure *
                  </Typography>
                  <Typography variant="body1" sx={{ width: "25ch" }}>
                    {daysPreIndex}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item>
              <ChevronRight />
            </Grid>
            <Grid item>
              {editMode && isAdmin ? (
                <TextField
                  required
                  label={"Days Post-Index Procedure"}
                  type="number"
                  sx={{ width: "25ch" }}
                  inputProps={{ readOnly: !editMode || !isAdmin }}
                  InputLabelProps={{ shrink: true }}
                  value={daysPostIndex}
                  error={displayErrors && daysPostIndex === ""}
                  helperText={
                    displayErrors && daysPostIndex === ""
                      ? "This field is required"
                      : ""
                  }
                  onChange={(event) => {
                    setDaysPostIndex(event.target.value);
                  }}
                />
              ) : (
                <>
                  <Typography variant="caption">
                    Days Post-Index Procedure *
                  </Typography>
                  <Typography variant="body1" sx={{ width: "25ch" }}>
                    {daysPostIndex}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
          {isAdmin && (
            <Grid item>
              <br />
              <Grid item>
                <Typography variant="body1">Notes Date Range <Tooltip
                  title="Empty value for this setting will use the default date range value."
                  placement="top-end"
                >
                  <InfoIcon
                    size="small"
                    sx={{ color: theme.palette.info.main, verticalAlign: "middle" }}
                  />
                </Tooltip></Typography>
              </Grid>
              <br />
              <Grid item container justifyContent={"space-between"}>
                <Grid item>
                  {editMode && isAdmin ? (
                    <TextField
                      label={"Days Pre-Index Procedure"}
                      type="number"
                      sx={{ width: "25ch" }}
                      inputProps={{ readOnly: !editMode || !isAdmin }}
                      InputLabelProps={{ shrink: true }}
                      value={notesPreIndex}
                      onChange={(event) => {
                        setNotesPreIndex(event.target.value);
                      }}
                    ></TextField>
                  ) : (
                    <>
                      <Typography variant="caption">
                        Days Pre-Index Procedure
                      </Typography>
                      <Typography variant="body1" sx={{ width: "25ch" }}>
                        {notesPreIndex}
                      </Typography>
                    </>
                  )}
                </Grid>
                <Grid item>
                  <ChevronRight />
                </Grid>
                <Grid item>
                  {editMode && isAdmin ? (
                    <TextField
                      label={"Days Post-Index Procedure"}
                      type="number"
                      sx={{ width: "25ch" }}
                      inputProps={{ readOnly: !editMode || !isAdmin }}
                      InputLabelProps={{ shrink: true }}
                      value={notesPostIndex}
                      onChange={(event) => {
                        setNotesPostIndex(event.target.value);
                      }}
                    />
                  ) : (
                    <>
                      <Typography variant="caption">
                        Days Post-Index Procedure
                      </Typography>
                      <Typography variant="body1" sx={{ width: "25ch" }}>
                        {notesPostIndex}
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
              <br />
            </Grid>
          )}
          <br />
          <Grid item>
            <Typography variant="body1">Patient Cohort</Typography>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="caption">Actual Number of Patients</Typography>
            <Typography variant="body1">{actualPatientCohort}</Typography>
          </Grid>
          <br />
          {isAdmin && (
            <Grid item>
              {editMode && isAdmin ? (
                <TextField
                  required
                  label="Expected Number of Patients"
                  variant="outlined"
                  type="number"
                  inputProps={{ readOnly: !editMode || !isAdmin }}
                  InputLabelProps={{ shrink: true }}
                  value={expectedPatientCohort}
                  onChange={(event) => {
                    setExpectedPatientCohort(event.target.value);
                  }}
                ></TextField>
              ) : (
                <>
                  <Typography variant="caption">
                    Expected Number of Patients
                  </Typography>
                  <Typography variant="body1">
                    {expectedPatientCohort}
                  </Typography>
                </>
              )}
            </Grid>
          )}
          <br />
          <RequireAuth withRole={"ADMIN"}>
            <Grid item>
              {editMode ? (
                <TextField
                  label="Manual Patient Cohort"
                  multiline
                  minRows={2}
                  sx={{ width: "60ch" }}
                  inputProps={{ readOnly: !editMode }}
                  value={manualCohort}
                  onChange={(event) => {
                    setManualCohort(event.target.value);
                  }}
                ></TextField>
              ) : (
                <>
                  <Typography variant="caption">
                    Manual Patient Cohort
                  </Typography>
                  <pre>
                    <Typography variant="body1">{manualCohort}</Typography>
                  </pre>
                </>
              )}
            </Grid>
          </RequireAuth>
          <br />
          <Grid item>
            <Typography variant="body1">
              List any specfic inclusion or exclusion criteria
            </Typography>
          </Grid>
          <br />
          <Grid item>
            {editMode ? (
              <TextField
                label="Details"
                multiline
                minRows={4}
                sx={{ width: "50ch" }}
                inputProps={{ readOnly: !editMode }}
                value={details}
                onChange={(event) => {
                  setDetails(event.target.value);
                }}
              ></TextField>
            ) : (
              <>
                <Typography variant="caption">Details</Typography>
                <Typography variant="body1" sx={{ width: "50ch" }}>
                  {details}
                </Typography>
              </>
            )}
          </Grid>
          <br />
          {editMode && (
            <Grid item>
              <TextField
                required
                label="Note/Reason for Updating Protocol"
                multiline
                minRows={2}
                sx={{ width: "50ch" }}
                inputProps={{ readOnly: !editMode }}
                value={updateDetails}
                error={displayErrors && updateDetails === ""}
                helperText={
                  displayErrors && updateDetails === ""
                    ? "This field is required"
                    : ""
                }
                onChange={(event) => {
                  setUpdateDetails(event.target.value);
                }}
              ></TextField>
            </Grid>
          )}
          <br />
          <Grid container item justifyContent={"right"}>
            {(editMode && (
              <>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setResetValues(true);
                    setUpdateDetails("");
                    setEditMode(false);
                    setDisplayErrors(false);
                    setProjectValues(project);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="update-button"
                  sx={{ marginLeft: "5px" }}
                  onClick={() => {
                    submitProjectSettings();
                  }}
                >
                  Update
                </Button>
              </>
            )) || (
              <Button variant="contained" onClick={() => setEditMode(true)}>
                Edit
              </Button>
            )}
          </Grid>
          <br />
          <Grid item>
            <Footer />
          </Grid>
        </div>
      </Grid>
    </div>
  );
}
