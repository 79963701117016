import React, { useState, useEffect, forwardRef } from "react";
import {
  Alert,
  Avatar,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  RichTreeView,
  TreeItem2,
  TreeItem2Label,
  useTreeViewApiRef,
} from "@mui/x-tree-view";
import { styled } from "@mui/system";
import { useAnnexData } from "../../../../services/hooks/useAnnexData";
import { HideSourceOutlined } from "@mui/icons-material";
import { getAnnexName } from "./CategorizeUtils";

const Item = styled(Typography)(({ theme }) => ({
  padding: "2px 8px;",
  textAlign: "left",
}));

const ItemLabel = styled(Typography)(({ theme }) => ({
  padding: "2px 8px;",
  textAlign: "left",
  fontWeight: "bold",
}));

const CustomTreeItem = styled(TreeItem2)(({ theme }) => ({
  ".MuiTreeItem-iconContainer": {
    paddingTop: theme.spacing(1),
  },
  ".MuiTreeItem-content": {
    alignItems: "stretch",
  },
}));

const HeightRestrictedTreeView = styled(RichTreeView)(({ theme }) => ({
  height: "500px",
  width: "100%",
  overflow: "auto",
}));

const renderTreeViewOptionTable = (node) => {
  if (!(node && node.id)) {
    return;
  }
  return (
    <Table
      size="small"
      className="imdrf-table"
      aria-label="a dense table"
      sx={{
        [`& .${tableCellClasses.root}`]: {
          borderBottom: "none",
        },
      }}
    >
      <TableBody>
        <TableRow>
          <TableCell padding="none" width={"10%"}>
            <ItemLabel variant="body2">Code:</ItemLabel>
          </TableCell>
          <TableCell padding="none" width={"90%"}>
            <Item variant="body2">{node.code}</Item>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell padding="none">
            <ItemLabel variant="body2">Term:</ItemLabel>
          </TableCell>
          <TableCell padding="none">
            <Item variant="body2">{node.term}</Item>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell padding="none">
            <ItemLabel variant="body2">Definition:</ItemLabel>
          </TableCell>
          <TableCell padding="none">
            <Item variant="body2">{node.definition}</Item>
          </TableCell>
        </TableRow>
        {!!node.nonImdrf && (
          <TableRow>
            <TableCell padding="none">
              <ItemLabel variant="body2">Non-IMDRF:</ItemLabel>
            </TableCell>
            <TableCell padding="none">
              <Item variant="body2">{node.nonImdrf}</Item>
            </TableCell>
          </TableRow>
        )}
        {!node.isSelectable && (
          <TableRow>
            <TableCell padding="none">
              <ItemLabel variant="body2">Status:</ItemLabel>
            </TableCell>
            <TableCell padding="none">
              <Item variant="body2">Not selectable</Item>
            </TableCell>
          </TableRow>
        )}
        {!node.isSelectable && (
          <TableRow>
            <TableCell padding="none" colSpan={2}>
              <Alert severity="error">
                Please use a more detailed term within the hierarchy.
              </Alert>
            </TableCell>
          </TableRow>
        )}
        {node.isOverridden && node.overrideCode && (
          <TableRow>
            <TableCell padding="none" colSpan={2}>
              <Alert severity="warning">
                {`This code cannot be exported. ${node.overrideCode.code} - ${node.overrideCode.definition} will be used instead if selected.`}
              </Alert>
            </TableCell>
          </TableRow>
        )}
        {node.isOverridden && !node.overrideCode && (
          <TableRow>
            <TableCell padding="none" colSpan={2}>
              <Alert severity="error">
                This code cannot be chosen or exported. Please choose an
                alternate.
              </Alert>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

function TreeItemLabelWithLoading(props) {
  const { loading, id: itemId, node, ...other } = props;
  return (
    <TreeItem2Label {...other}>
      {node.term ? (
        renderTreeViewOptionTable(node)
      ) : (
        <ItemLabel variant="body2">{node.label}</ItemLabel>
      )}
      {loading && <CircularProgress size="1em" sx={{ marginLeft: 0.5 }} />}
    </TreeItem2Label>
  );
}

const TreeItemWithLoading = forwardRef(function TreeItemWithLoadingInternal(
  props,
  ref
) {
  const node = props.apiref.current.getItem(props.itemId);
  return (
    <CustomTreeItem
      ref={ref}
      {...props}
      slots={{
        label: TreeItemLabelWithLoading,
      }}
      slotProps={{
        label: {
          id: props.itemId,
          node: node,
        },
      }}
      disabled={!node.isSelectable}
    />
  );
});

export default function IMDRFComponent({
  projectId,
  imdrfAnnex,
  selectedCode,
  setSelectedCode,
}) {
  const [annexes, setAnnexes] = useState(getCategoryAnnexList(imdrfAnnex));
  const [annexDataFetched, setAnnexDataFetched] = useState(false);
  const [items, setItems] = useState([]);
  const [inputText, setInputText] = useState("");
  const [expandedItems, setExpandedItems] = useState([]);
  const apiRef = useTreeViewApiRef();

  const { getAnnexData } = useAnnexData();

  function getCategoryAnnexList(imdrfAnnex) {
    let annexList = [];
    if (imdrfAnnex != null && imdrfAnnex !== "") {
      let annexIdList = imdrfAnnex.split(",");
      annexIdList.forEach((a) =>
        annexList.push({
          id: a,
          label: getAnnexName(a),
          children: [],
        })
      );
    }
    return annexList;
  }

  function handleSearchChange(event) {
    let searchTerm = event.target.value;
    setInputText(searchTerm);
    filterItems(searchTerm);
  }

  function filterItems(searchTerm) {
    let filtered = [];
    let expanded = [];

    if (searchTerm?.length > 2) {
      searchTerm = searchTerm.toLowerCase();
      //Annex
      annexes.forEach((item) => {
        let newItem = null;
        let parents = item.children;
        let newParents = [];

        if (parents && parents.length > 0) {
          //Level 1 codes
          parents.forEach((parent) => {
            let newParent = null;
            let newChildren = [];
            let children = parent.children;
            if (children && children.length > 0) {
              //Level 2 codes
              children.forEach((child) => {
                let newChild = null;
                let newGrandChildren = [];
                let grandChildren = child.children;

                //Level 3 codes
                if (grandChildren && grandChildren.length > 0) {
                  newGrandChildren = codeListContainsSearchText(
                    grandChildren,
                    searchTerm
                  );
                }

                if (newGrandChildren.length > 0) {
                  newChild = child;
                  newChild.children = newGrandChildren;
                  newChildren.push(newChild);
                  expanded.push(newChild.id.toString());
                } else if (codeContainsSearchText(child, searchTerm)) {
                  newChildren.push(child);
                  expanded.push(child.id.toString());
                }
              });
            }

            if (newChildren.length > 0) {
              newParent = parent;
              newParent.children = newChildren;
              newParents.push(newParent);
              expanded.push(newParent.id.toString());
            } else if (codeContainsSearchText(parent, searchTerm)) {
              newParents.push(parent);
              expanded.push(parent.id.toString());
            }
          });
        }

        if (newParents.length > 0) {
          newItem = {
            id: item.id,
            label: item.label,
          };
          newItem.children = newParents;
          filtered.push(newItem);
          expanded.push(newItem.id.toString());
        }
      });
      setItems(filtered);
      setExpandedItems(expanded);
    } else {
      setItems(annexes);
      setExpandedItems([]);
    }
  }

  function codeListContainsSearchText(codeList, searchTerm) {
    return codeList.filter(function (el) {
      return codeContainsSearchText(el, searchTerm);
    });
  }

  function codeContainsSearchText(imdrfCode, searchTerm) {
    return (
      imdrfCode.code.toLowerCase().includes(searchTerm) ||
      imdrfCode.term.toLowerCase().includes(searchTerm) ||
      imdrfCode.definition.toLowerCase().includes(searchTerm)
    );
  }

  useEffect(() => {
    async function fetchData(annex) {
      await getAnnexData(annex, projectId);
      // set only unique items
      setItems((prev) => {
        let next = prev.includes(annex) ? prev : [...prev, annex];
        next.sort((a, b) => a.id.localeCompare(b.id));
        return next;
      });
    }

    async function fetchAllData(annexes) {
      for (const annex of annexes) {
        await fetchData(annex);
      }
    }

    if (annexes?.length > 0 && !annexDataFetched) {
      fetchAllData(annexes).then(() => {
        setAnnexes(annexes);
        setAnnexDataFetched(true);
      })
    }

    // disabling linter for this dependency array because adding getAnnexData causes this to re-fire infinitely
    // eslint-disable-next-line
  }, [annexes, annexDataFetched]);

  return (
    <Stack direction="column" spacing={1.5} className="categorize-modal">
      <TextField
        className="categorize-modal-textfield"
        label="IMDRF Code"
        fullWidth
        size="small"
        value={inputText}
        onChange={handleSearchChange}
      />
      {!annexDataFetched && (
        <Stack
          direction="column"
          spacing={1.5}
          alignItems={"center"}
          width="100%"
          style={{ marginTop: "25px" }}
        >
          <Avatar style={{ padding: "5px" }}>
            <CircularProgress />
          </Avatar>
          <Typography variant="h5" fontWeight="bold">
            Loading IMDRF Codes
          </Typography>
          <Typography variant="body2">
            Please wait while we load the IMDRF codes.
          </Typography>
        </Stack>
      )}
      {(annexDataFetched && items.length > 0 && (
        <HeightRestrictedTreeView
          apiRef={apiRef}
          expandedItems={expandedItems}
          expansionTrigger="iconContainer"
          onItemExpansionToggle={(event, itemId, isExpanded) => {
            // stop propagation to prevent the selected handler from triggering
            // when a user expands a selectable option
            event.stopPropagation();
            if (isExpanded) {
              setExpandedItems([...expandedItems, `${itemId}`]);
            } else {
              setExpandedItems(expandedItems.filter((e) => e !== itemId));
            }
          }}
          selectedItems={selectedCode}
          onSelectedItemsChange={(event, itemIds) => {
            const node = apiRef.current.getItem(itemIds);
            if (
              (node.isSelectable && !node.isOverridden) ||
              (!node.isSelectable && node.isOverridden && node.overrideCode)
            ) {
              setSelectedCode(node);
            }
          }}
          items={items}
          getItemId={(item) => `${item.id}`}
          getItemLabel={(item) => item.label || item.term}
          slots={{ item: TreeItemWithLoading }}
          slotProps={{
            item: {
              apiref: apiRef,
            },
          }}
        />
      )) ||
        (annexDataFetched && (
          <Stack
            direction="column"
            spacing={1.5}
            alignItems={"center"}
            width="100%"
            style={{ marginTop: "25px" }}
          >
            <Avatar style={{ padding: "5px" }}>
              <HideSourceOutlined color="disabled" fontSize="large" />
            </Avatar>
            <Typography variant="h5" fontWeight="bold">
              No IMDRF Codes
            </Typography>
            <Typography variant="body2">
              There are no IMDRF codes that match this annex and term.
            </Typography>
          </Stack>
        ))}
    </Stack>
  );
}
