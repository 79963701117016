import React, { useState, useCallback, useContext } from "react";
import {
  Chip,
  Divider,
  Link,
  Stack,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import VisitsDrawer from "../VisitsDrawer";
import InfoIcon from "@mui/icons-material/Info";
import OfflineBoltOutlinedIcon from "@mui/icons-material/OfflineBoltOutlined";
import theme from "../../../../styles/theme";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import { compareTwoValues, getDiffDaysMessage, getIndexMessage } from "../../../../utils/main";
import { CurrentPatient } from "../../ProjectDetails";

export default function VisitsInfo() {
  const encounterSortEnum = {
    title: "diffDays",
    priority: "priority",
    related: "isRelated",
  };
  const [sortBy, setSortBy] = useState(encounterSortEnum.title);
  const [sortDir, setSortDir] = useState("asc");
  const { encounters, setEncounters } = useContext(CurrentPatient);
  const [drawer, setDrawer] = useState(false);
  const [visitToLoad, setVisitToLoad] = useState({});

  function openDrawer(visit) {
    setVisitToLoad(visit.id);
    setDrawer(true);
  }

  function toggleDrawer() {
    setDrawer(false);
  }

  function sortByTitle() {
    let newSortDir;
    let newSortBy = encounterSortEnum.title;
    if (sortBy !== encounterSortEnum.title || sortDir === "asc") {
      newSortDir = "desc";
    } else {
      newSortDir = "asc";
    }
    setSortBy(newSortBy);
    setSortDir(newSortDir);
    sortEncounters(encounters, newSortBy, newSortDir);
  }

  function sortByPriority() {
    let newSortDir;
    let newSortBy = encounterSortEnum.priority;
    if (sortBy !== encounterSortEnum.priority || sortDir === "desc") {
      newSortDir = "asc";
    } else {
      newSortDir = "desc";
    }
    setSortBy(newSortBy);
    setSortDir(newSortDir);
    sortEncounters(encounters, newSortBy, newSortDir);
  }

  function sortByRelated() {
    let newSortDir;
    let newSortBy = encounterSortEnum.related;
    if (sortBy !== encounterSortEnum.related || sortDir === "asc") {
      newSortDir = "desc";
    } else {
      newSortDir = "asc";
    }
    setSortBy(newSortBy);
    setSortDir(newSortDir);
    sortEncounters(encounters, newSortBy, newSortDir);
  }

  const sortEncounters = useCallback(
    (visits, sortColumn, sortDirection) => {
      if (sortColumn === encounterSortEnum.related) {
        return visits.sort((a) => {
          if (sortDirection === "asc") {
            return a.isRelated || a.isRelated === null ? -1 : 1;
          }
          return a.isRelated || a.isRelated === null ? 1 : -1;
        });
      }
      return visits.sort((a, b) => {
        if (sortDirection === "asc") {
          return compareTwoValues(a[sortColumn], b[sortColumn]);
        }
        return compareTwoValues(b[sortColumn], a[sortColumn]);
      });
    },
    [encounterSortEnum.related]
  );

  const handleSingleVisitUpdate = useCallback(
    (data) => {
      const updatedList = encounters.map((item) => {
        return item.id === data.id
          ? {
              ...item,
              ...data,
            }
          : item;
      });
      setEncounters(updatedList);
    },
    [encounters, setEncounters]
  );

  return (
    <>
      {drawer && (
        <VisitsDrawer
          toggleDrawer={() => toggleDrawer()}
          visitId={visitToLoad}
          updatedList={handleSingleVisitUpdate}
        />
      )}
      <div className="visits-header">
        <div className="visits-row-date">
          <TableSortLabel
            active={sortBy === encounterSortEnum.title}
            direction={sortDir}
            onClick={sortByTitle}
          >
            <Typography>Date </Typography>
          </TableSortLabel>
        </div>
        <div className="visits-row">
          <Divider orientation="vertical" flexItem />
          <TableSortLabel
            active={sortBy === encounterSortEnum.priority}
            direction={sortDir}
            onClick={sortByPriority}
          >
            <Typography className="left-divider">Priority</Typography>
          </TableSortLabel>
          <Tooltip
            title="Visits with a priority icon may include evidence of an adverse event, clinical or performance failure as identified by the 3Aware system."
            placement="top-end"
          >
            <InfoIcon size="small" sx={{ color: theme.palette.info.main }} />
          </Tooltip>
          <Divider orientation="vertical" flexItem className="right-divider" />
        </div>
        <div className="visits-row">
          <TableSortLabel
            active={sortBy === encounterSortEnum.related}
            direction={sortDir}
            onClick={sortByRelated}
          >
            <Typography className="left-divider">Related </Typography>
          </TableSortLabel>
        </div>
      </div>
      <Divider />
      <Stack>
        {encounters.map((visit) => (
          <div key={visit.id}>
            <div className={"visit-item"}>
              <div className={"visit-data"}>
                {visit.indexProcedure === true ? (
                  <Link
                    className="index-procedure"
                    component="button"
                    variant="subtitle2"
                    onClick={() => {
                      openDrawer(visit);
                    }}
                  >
                    {getIndexMessage()}
                  </Link>
                ) : (
                  <Link
                    className="post-day-index"
                    component="button"
                    variant="subtitle2"
                    onClick={() => {
                      openDrawer(visit);
                    }}
                  >
                    {getDiffDaysMessage(visit.diffDays)}
                  </Link>
                )}
                {visit.encounterType && visit.encounterNumber && (
                  <>
                    <div className="visit-type">
                      <Typography
                        className="label"
                        sx={{ color: theme.palette.text.secondary }}
                      >
                        {"Visit ID: "}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ color: theme.palette.text.secondary }}
                      >
                        {visit.encounterNumber}
                      </Typography>
                    </div>
                    <div className="visit-type">
                      <Typography
                        className="label"
                        sx={{ color: theme.palette.text.secondary }}
                      >
                        {"Type: "}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ color: theme.palette.text.secondary }}
                      >
                        {visit.encounterType}
                      </Typography>
                    </div>
                  </>
                )}
              </div>
              <div className="visit-icon-box">
                {visit && visit.priority > 0 && (
                  <OfflineBoltOutlinedIcon
                    sx={{ color: theme.palette.info.main }}
                  />
                )}
              </div>
              <div className="visit-actions">
                {visit.isRelated === false && (
                  <Chip
                    label="Unrelated"
                    size="small"
                    sx={{ borderRadius: 3 }}
                    onDelete={() => undefined}
                    deleteIcon={
                      <Tooltip title={visit.reason}>
                        <StickyNote2OutlinedIcon
                          fontSize="small"
                          style={{ color: theme.palette.action.active }}
                        />
                      </Tooltip>
                    }
                  />
                )}
              </div>
            </div>
            <Divider />
          </div>
        ))}
      </Stack>
    </>
  );
}
