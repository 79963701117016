import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Stack,
} from "@mui/material";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import { green, red, blueGrey } from "@mui/material/colors";

export default function ListItem({
  index,
  style,
  patientSummary,
  patientSelected,
  onPatientSelected,
}) {
  const year =
    patientSummary && patientSummary.procedureDate
      ? new Date(patientSummary.procedureDate).getUTCFullYear()
      : 0;
  const active = patientSummary.id === patientSelected.id;

  return (
    <Card
      key={index}
      style={style}
      className={"patient-list-item-card"}
      onClick={() => onPatientSelected(patientSummary)}
      sx={{ bgcolor: active ? "action.selected" : "" }}
    >
      <CardActionArea>
        <CardContent>
          {patientSummary.isIncluded && (
            <Box
              className="patient-list-icon-bubble"
              sx={{ bgcolor: green[50] }}
            >
              <ThumbUpOutlinedIcon sx={{ color: green[600] }} />
            </Box>
          )}
          {patientSummary.isIncluded === false && (
            <Box
              className="patient-list-icon-bubble"
              sx={{ background: red[50] }}
            >
              <BlockOutlinedIcon sx={{ color: red[600] }} />
            </Box>
          )}
          {patientSummary.isIncluded === null && (
            <Box
              className="patient-list-icon-bubble"
              sx={{ background: blueGrey[50] }}
            >
              <PersonOutlinedIcon sx={{ color: blueGrey[600] }} />
            </Box>
          )}

          <Stack
            direction={"column"}
            alignItems={"flex-start"}
            className="patient-list-info"
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              className="patient-list-top"
            >
              <Typography variant="body1">{`Patient ${patientSummary.patientNumber}`}</Typography>
              <Stack direction={"row"} className="categorization">
                {patientSummary.adverseEvents && <WarningAmberOutlinedIcon />}
                {patientSummary.clinicalBenefit && (
                  <MedicalServicesOutlinedIcon />
                )}
                {patientSummary.performance && <TrendingUpOutlinedIcon />}
              </Stack>
            </Stack>
            <Typography variant="body2">Index Procedure: {year}</Typography>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
