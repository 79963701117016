import { useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  projectVersions,
  capitalize,
  deviceApiPrefix,
  apiPrefix,
} from "../../../utils/main";
import { freemiumSteps, premiumSteps } from "./steps/stepsConfig";
import { CircularProgress, Drawer, TextField } from "@mui/material";
import MakeModalComponent from "../MakeModalComponent";
import DrawerTitle from "./DrawerTitle";
import StandardDrawerFooter from "../StandardDrawerFooter";
import { isInputNewDeviceValid } from "../../../models/upsertProject";
import { useFetch } from "../../../services/hooks/useFetch";
import { useFusionAuth } from "@fusionauth/react-sdk";

const subStepsEnum = {
  inputNewDevice: "inputNewDevice",
};

export default function EditProject({
  defaultValues,
  handleSave,
  onCancel,
  mode,
  reason,
  handleReasonChange,
}) {
  const [subStep, setSubStep] = useState({});
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const [changes, setChanges] = useState({});
  const [keywords, setKeywords] = useState([]);
  const [currStep, setCurrStep] = useState(0);
  const [displayErrors, setDisplayErrors] = useState(false);
  const [makingChanges, setMakingChanges] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [buttonText, setButtonText] = useState({
    left: "Cancel",
    right: "Next",
  });
  const steps =
    defaultValues.version === projectVersions.premium
      ? premiumSteps
      : freemiumSteps;
  const isCreate = mode === "create";

  const projectData = { ...defaultValues, ...changes };

  const { userInfo } = useFusionAuth();
  const isAdmin = userInfo?.roles?.includes("ADMIN");

  const [companySettingMinDate, setCompanySettingMinDate] = useState(
    new Date(2018)
  );
  const {
    data: companySettings,
    isLoading,
    fetch: fetchCompanySettings,
  } = useFetch();
  const {
    data: keywordData,
    isLoading: keywordsLoading,
    fetch: fetchKeywords,
    status: keywordsStatus,
  } = useFetch();
  const {
    update: sendMissingDevice,
    status: missingDeviceStatus,
    isLoading: missingDeviceSending,
  } = useFetch();

  function onUpdateData(newChanges) {
    setMakingChanges(true);
    setDisableNext(false);
    setChanges((prev) => {
      return { ...prev, ...newChanges };
    });
  }

  function onDisplayInputNewDevice() {
    setDisplayErrors(false);
    setSubStep({ id: subStepsEnum.inputNewDevice, finished: false });
    setButtonText({
      left: "Back",
      right: "Send Us Your Device Information",
    });
  }

  function handleSendMissingDevice() {
    setLoading(true);
    sendMissingDevice(
      `${apiPrefix}/devices/missing`,
      {
        deviceModels: projectData.deviceModels,
        otherInformation: projectData.otherInformation,
      },
      "POST"
    );
  }

  useEffect(() => {
    if (!missingDeviceSending && missingDeviceStatus === 204) {
      setLoading(false);
      setSubStep((prev) => ({ ...prev, finished: true }));
      setButtonText({
        left: "Cancel",
        right: "",
      });
    }
  }, [
    missingDeviceSending,
    missingDeviceStatus,
    setLoading,
    setSubStep,
    setButtonText,
  ]);

  function handleNext() {
    const isDataCompleted =
      isCreate && subStep.id === subStepsEnum.inputNewDevice
        ? isInputNewDeviceValid
        : steps[currStep].validation;
    if (!isDataCompleted(projectData, keywords, companySettingMinDate)) {
      setDisplayErrors(true);
      setDisableNext(true);
      return;
    }
    if (isCreate && subStep.id === subStepsEnum.inputNewDevice) {
      handleSendMissingDevice();
      return;
    }
    if (
      !isCreate &&
      currStep === steps.length - 1 &&
      reason.trim() === "" &&
      makingChanges
    ) {
      document.getElementById("reason").scrollIntoView();
      setDisplayErrors(true);
      return;
    }
    setDisplayErrors(false);
    setDisableNext(false);
    if (currStep === steps.length - 1) {
      if (!makingChanges) {
        setLoading(false);
        return;
      }
      setLoading(true);
      handleSave(projectData, keywords);
    } else {
      const nextStep = currStep + 1;
      if (!isCreate) {
        // validate next step, & display errors + disable progression button if necessary
        const isDataCompleted = steps[nextStep].validation;
        if (!isDataCompleted(projectData, keywords, companySettingMinDate)) {
          setDisplayErrors(true);
          setDisableNext(true);
        }
      }
      setCurrStep(nextStep);
    }
  }

  function handleBack() {
    if (isCreate) {
      if (subStep.id === subStepsEnum.inputNewDevice && subStep.finished) {
        onCancel();
        return;
      }
      if (subStep.id === subStepsEnum.inputNewDevice) {
        setButtonText({
          left: "Cancel",
          right: "Next",
        });
        setSubStep({});
        setDisplayErrors(false);
        return;
      }
    }
    if (currStep === 0) {
      onCancel();
      return;
    }
    setDisplayErrors(false);
    setDisableNext(false);
    setCurrStep((prev) => --prev);
  }

  function updateKeyword(keywordUpdated, index) {
    setMakingChanges(true);
    setDisplayErrors(false);
    setKeywords((prev) => {
      const updated = [...prev];
      updated[index] = keywordUpdated;
      return updated;
    });
  }

  useEffect(() => {
    if (!projectId || keywordsLoading) {
      return;
    }
    if (keywordsStatus === null) {
      fetchKeywords(`${apiPrefix}/keywords?projectId=${projectId}`);
    }
  }, [projectId, fetchKeywords, keywordsLoading, keywordsStatus]);

  useEffect(() => {
    if (!keywordsLoading && keywordData.length > 0) {
      const keywordsFiltered = keywordData.filter(
        (keyword) =>
          keyword.projectId === projectId && keyword.target === "body"
      );
      setKeywords(keywordsFiltered);
    }
  }, [keywordsLoading, keywordData, setKeywords, projectId]);

  useEffect(() => {
    fetchCompanySettings(`${deviceApiPrefix}/companies/settings`);
  }, [fetchCompanySettings]);

  useEffect(() => {
    if (!isLoading && companySettings) {
      const s = companySettings.filter((s) => s.name === "START_DATE_MIN")[0];
      if (s) {
        setCompanySettingMinDate(new Date(parseInt(s.value), 0));
      }
    }
  }, [isLoading, companySettings]);

  useEffect(() => {
    if (currStep === steps.length - 1) {
      setButtonText({ left: "Back", right: `${capitalize(mode)} Project` });
      return;
    }
    if (currStep === 0) {
      setButtonText({ left: "Cancel", right: "Next" });
      return;
    }
    setButtonText({ left: "Back", right: "Next" });
  }, [currStep, steps.length, mode]);

  function getProps(id, index) {
    const defaultProps = {
      key: id,
      displayContent: index === currStep,
      displayErrors,
    };

    switch (id) {
      case "prioritization":
        return { ...defaultProps, keywords, updateKeyword };
      case "device-details":
        if (isCreate) {
          return {
            canInputNewDevice: true,
            ...defaultProps,
            onDisplayInputNewDevice,
            subStep,
            onUpdateData,
            projectData,
          };
        }
      // intentional fallthrough in the update case
      default: {
        const disableDateRange =
          !isCreate &&
          defaultValues.version === projectVersions.premium &&
          !isAdmin;
        const disablePreAndPostIndexProcedureRange = !isCreate && !isAdmin;
        return {
          ...defaultProps,
          onUpdateData,
          projectData,
          disableDateRange,
          disablePreAndPostIndexProcedureRange,
          companySettingMinDate,
        };
      }
    }
  }

  const ModalContent = (
    <div className="project-drawer-content">
      {steps.map((item, index) => {
        const { component: Component, id } = item;
        const props = getProps(id, index);
        // To see the component rendered in a step, use stepsConfig.js as reference
        return <Component key={id} {...props} />;
      })}
      {!isCreate && currStep === steps.length - 1 && makingChanges && (
        <TextField
          className="project-reason"
          label="Note/Reason for Updating the Project Details *"
          variant="outlined"
          fullWidth
          size="medium"
          value={reason}
          onChange={handleReasonChange}
          sx={{ bgcolor: "common.white" }}
          helperText={"This field is required"}
          error={displayErrors && reason.trim() === ""}
          id="reason"
        />
      )}
    </div>
  );

  return isLoading ? (
    <CircularProgress />
  ) : (
    <Drawer
      anchor="right"
      open={true}
      onClose={onCancel}
      PaperProps={{
        sx: { width: "min(100%, 624px)" },
      }}
    >
      <MakeModalComponent
        header={
          <DrawerTitle
            version={defaultValues.version}
            mode={mode}
            onClose={onCancel}
          />
        }
        content={ModalContent}
        footer={
          <StandardDrawerFooter
            leftBtnLabel={buttonText.left}
            onLeftBtn={handleBack}
            rightBtnLabel={buttonText.right}
            onRightBtn={handleNext}
            loading={loading}
            displayLoadingRightBtn={true}
            disableRightBtn={disableNext}
          />
        }
        classes={{ modalCard: `${mode}-project-drawer` }}
      />
    </Drawer>
  );
}
