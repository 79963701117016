import React from "react";
import { Typography } from "@mui/material";
import DOMPurify from "dompurify";

export default function NoteText({ text, variant = "body1", className = "" }) {
  const sanitiziedText = DOMPurify.sanitize(text, {
    ALLOWED_TAGS: ["highlight"],
  });

  const finalText = sanitiziedText.replaceAll("\n", "<br />");

  return (
    <Typography
      variant={variant}
      dangerouslySetInnerHTML={{ __html: finalText }}
      className={className}
    />
  );
}
