import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Stack,
  Switch,
  FormControlLabel,
} from "@mui/material";
import DateRange from "../../DateRange/DateRange";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { RequireAuth } from "@fusionauth/react-sdk";

export default function Protocol({
  displayContent,
  displayErrors,
  onUpdateData,
  projectData,
  disableDateRange,
  disablePreAndPostIndexProcedureRange,
  companySettingMinDate,
}) {
  const [manualChecked, setManualChecked] = useState(
    projectData.isManualCohort ?? false
  );
  const [manualCohortArray, setManualCohortArray] = useState(
    projectData.isManualCohort && projectData.patientIdList
      ? projectData.patientIdList
      : []
  );

  function handleStartDate(date) {
    onUpdateData({ startDate: date });
  }

  function handleEndDate(date) {
    onUpdateData({ endDate: date });
  }

  if (!displayContent) {
    return null;
  }

  function handleChangeDetails(event) {
    if (event.target.value.length <= 500) {
      onUpdateData({ additionalCriteria: event.target.value });
    }
  }

  function handleChangeNumberOfPatient(event) {
    if (event.target.value === "") {
      onUpdateData({ expectedPatientCount: event.target.value });
      return;
    }
    const numberParse = parseInt(event.target.value);
    if (isNaN(numberParse)) {
      return;
    }
    if (numberParse > 0) {
      onUpdateData({ expectedPatientCount: numberParse });
    }
  }

  function handleChangeNumPreIndexProcedure(event) {
    if (event.target.value === "") {
      onUpdateData({ defaultPreIndexDays: event.target.value });
      return;
    }
    const numberParse = parseInt(event.target.value);
    if (isNaN(numberParse)) {
      return;
    }
    if (numberParse > 0) {
      onUpdateData({ defaultPreIndexDays: numberParse });
    }
  }

  function handleChangeNumPostIndexProcedure(event) {
    if (event.target.value === "") {
      onUpdateData({ defaultPostIndexDays: event.target.value });
      return;
    }
    const numberParse = parseInt(event.target.value);
    if (isNaN(numberParse)) {
      return;
    }
    if (numberParse > 0) {
      onUpdateData({ defaultPostIndexDays: numberParse });
    }
  }

  const validation = projectData.expectedPatientCount === "";
  const preIndexValidation = projectData.defaultPreIndexDays === "";
  const postIndexValidation = projectData.defaultPostIndexDays === "";

  const toggleManualChecked = () => {
    onUpdateData({ isManualCohort: !manualChecked });
    setManualChecked(!manualChecked);
  };

  const handleUpdateManualCohort = (event) => {
    let tempCohortArray = event.target.value
      .split(",")
      .map((v) => v.trim())
      .filter((v) => v.length > 0);
    setManualCohortArray(tempCohortArray);
    onUpdateData({
      expectedPatientCount: tempCohortArray.length,
      patientIdList: tempCohortArray,
    });
  };

  return (
    <Box className="protocol-section" sx={{ bgcolor: "grey.50" }}>
      <Typography variant="body2">
        Enter some details for your project and we’ll help you stay on track to
        meet the requirements. If you need to exclude specific patient
        populations you can do so once the project is upgraded to Premium.
      </Typography>
      <Typography color="text.secondary">* Required fields</Typography>
      <section className="subsection">
        <Typography variant="body1">Procedure Date Range</Typography>
        <DateRange
          displayErrors={displayErrors}
          startDate={projectData.startDate}
          endDate={projectData.endDate}
          onStartDateChange={handleStartDate}
          onEndDateChange={handleEndDate}
          disabled={disableDateRange}
          minDate={companySettingMinDate}
        />
      </section>
      <section className="subsection">
        <Typography variant="body1">Visit Date Range</Typography>
        <div className="date-range">
          <TextField
            label="Days Pre-Index Procedure"
            type="number"
            value={projectData.defaultPreIndexDays}
            min="0"
            step="1"
            error={displayErrors && preIndexValidation}
            onChange={handleChangeNumPreIndexProcedure}
            disabled={disablePreAndPostIndexProcedureRange}
            inputProps={{ "data-testid": "Protocol-pre-index-procedure" }}
            required
            helperText={"Please enter a value greater than 0"}
            sx={{ width: "233px" }}
          />
          <div className="visit-range-section-chevron">
            <ChevronRightOutlinedIcon className="icon" />
          </div>
          <TextField
            label="Days Post-Index Procedure"
            type="number"
            value={projectData.defaultPostIndexDays}
            min="0"
            step="1"
            error={displayErrors && postIndexValidation}
            onChange={handleChangeNumPostIndexProcedure}
            disabled={disablePreAndPostIndexProcedureRange}
            inputProps={{ "data-testid": "Protocol-post-index-procedure" }}
            required
            helperText={"Please enter a value greater than 0"}
            sx={{ width: "233px" }}
          />
        </div>
      </section>
      <Stack width={"100%"}>
        <Typography variant="body1">Patient Cohort</Typography>
        <Stack
          width={"100%"}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <TextField
            label="Number of Patients"
            value={projectData.expectedPatientCount}
            type="number"
            min="0"
            step="1"
            disabled={manualChecked}
            error={displayErrors && validation}
            onChange={handleChangeNumberOfPatient}
            inputProps={{ "data-testid": "Protocol-number-of-patients" }}
            required
            helperText={"Please enter a value greater than 0"}
          />
          <RequireAuth withRole={"ADMIN"}>
            <FormControlLabel
              control={<Switch checked={manualChecked} />}
              onChange={toggleManualChecked}
              label="Manual Patient Entry"
            />
          </RequireAuth>
        </Stack>
      </Stack>
      {manualChecked && (
        <RequireAuth withRole={"ADMIN"}>
          <Stack width={"100%"}>
            <TextField
              multiline
              defaultValue={manualCohortArray.join(", ")}
              label={"Fixed Cohort"}
              onChange={handleUpdateManualCohort}
            />
          </Stack>
        </RequireAuth>
      )}
      <div className="subsection">
        <Typography variant="body1">
          List any specific inclusion or exclusion criteria
        </Typography>
        <TextField
          label="Details"
          value={projectData.additionalCriteria || ""}
          onChange={handleChangeDetails}
          minRows={6}
          multiline
          inputProps={{ "data-testid": "Protocol-details" }}
          fullWidth
          helperText={
            projectData.additionalCriteria
              ? `${projectData.additionalCriteria.length} of 500 characters`
              : "0 of 500 characters"
          }
        />
      </div>
    </Box>
  );
}
