import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Typography,
  Chip,
  Box,
  LinearProgress,
  Link
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  DataGridPremium,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton
} from "@mui/x-data-grid-premium";
import {
  getAttributeValue,
  getCategoryIcon,
  getCategoryName,
  getOtherData
} from "./CategorizeUtils";
import { CurrentProject, CurrentPatient } from "../../ProjectDetails";

function CustomToolBar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

export default function PatientAttributeDataGrid({
                                                   loading = false,
                                                   title,
                                                   handleRowEdit
                                                 }) {
  const [data, setData] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const { getImdrfEnabled, attributeTypes } = useContext(CurrentProject);
  const { patient } = useContext(CurrentPatient);
  const imdrfEnabled = getImdrfEnabled();

  const renderValueCell = (param) => {
    return <Link
      component="button"
      variant="subtitle2"
      style={{ textAlign: "left" }}>{param.value}</Link>;
  };

  const handleCellClick = (params, event, details) => {
    if (params.field === "value") {
      handleRowEdit(params, event, details);
    }
  };

  const fields = [
    {
      field: "categoryIcon",
      headerName: "",
      sortable: false,
      width: 50,
      align: "center",
      disableColumnSelector: true,
      disableColumnFilter: true,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.icon;
      }
    },
    {
      field: "category",
      headerName: "Category",
      width: 150
    },
    {
      field: "value",
      headerName: "Value",
      width: 275,
      disableColumnFilter: true,
      renderCell: renderValueCell
    },
    {
      field: "otherData",
      headerName: "Other Data",
      sortable: false,
      width: 200,
      disableColumnFilter: true,
      renderCell: (params) => {
        return (
          <ul type="none" style={{ padding: 0 }}>
            {params.value.map((fieldData, index) => (
              <li key={index}>{fieldData}</li>
            ))}
          </ul>
        );
      }
    }
  ];

  useEffect(() => {
    let attributeData = [];
    if (patient.attributes && patient.attributes.length > 0) {
      patient.attributes.forEach((a) => {
        let categoryName = getCategoryName(attributeTypes, a);
        let attribute = {
          id: a.id,
          icon: getCategoryIcon(a.attributeTypeId),
          category: categoryName,
          value: getAttributeValue(imdrfEnabled, attributeTypes, a),
          otherData: getOtherData(imdrfEnabled, attributeTypes, a)
        };

        attributeData.push(attribute);
      });
    }
    setData(attributeData);
    if (attributeData.length > 0) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [patient.attributes, attributeTypes, imdrfEnabled]);

  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion key={title} className={"details-table"} expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon onClick={() => toggleAccordion()} />}
        aria-controls={title + "-content"}
        id={title + "-header"}
      >
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <Typography variant="h6" className={"categorization-row-title"}>
            {title}
          </Typography>
          <Chip size="small" label={data.length} />
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ display: "flex", padding: 1 }}>
        <Box
          sx={{
            minHeight: 200,
            maxHeight: 500,
            width: "100%",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <DataGridPremium
            initialState={{
              sorting: {
                sortModel: [{ field: "category", sort: "asc" }]
              }
            }}
            slots={{
              toolbar: CustomToolBar,
              loadingOverlay: LinearProgress
            }}
            loading={loading}
            getRowHeight={() => "auto"}
            density="compact"
            getRowId={() => crypto.randomUUID()}
            columns={fields}
            rows={data}
            filterMode="client"
            onRowDoubleClick={handleRowEdit}
            onCellClick={handleCellClick}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
